<template>
  <b-card>
    <b-row>
      <!-- 
          -
          -
          -
          -
          -
          -
          -
          -
       -->
      <!-- Right Col: Table -->
      <b-col lg="12" md="12">
        <b-row>
          <b-col class="d-flex flex-column" lg="6" md="6">
            <h3 class="font-weight-bold" style="">{{ inventaires.libelle }}</h3>
            <span class="font-weight-bold">
              Créer le {{ format_date(inventaires.created_at) }}</span
            >
            <span> Effectué par : {{ inventaires.user.nom }} </span>
          </b-col>
        </b-row>
      </b-col>

      <!-- liste des reglements -->
      <!-- 
          -
          -
          -
          -
          -
          -
          -
          -
       -->
      <b-col cols="12">
        <div class="mt-3">
          <b-col>
            <h3>
              <feather-icon icon="TrendingUpIcon" class="mr-75" />
              Liste des articles
            </h3>
          </b-col>

          <b-col cols="12" md="4" offset-md="8" class="my-1">
            <div class="d-flex align-items-center">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="state.filter"
                  class="d-inline-block"
                  placeholder="Rechercher par : libelle, date, montant..."
                />
              </b-input-group>
            </div>
          </b-col>
          <b-table
            hover
            responsive
            primary-key="id"
            :per-page="state.perPage"
            :current-page="state.currentPage"
            :items="articles"
            :fields="tableColumns"
            :filter="state.filter"
            show-empty
            empty-text="Aucun reglement effectuez !"
            class="bg-white text-left"
          >
            <template #cell(qte)="data">
              <div class="py-1">
                <b-badge variant="info">
                  <span>{{ data.item.qte }}</span>
                </b-badge>
              </div>
            </template>

            <template #cell(qte_restante)="data">
              <div class="py-1">
                <b-badge variant="warning">
                  <span class="text-bold">{{ data.item.qte_restante }}</span>
                </b-badge>
              </div>
            </template>

            <template #cell(article_restant)="data">
              <div class="py-1">
                <b-badge variant="warning">
                  <span class="text-bold">{{ data.item.pivot.article_restant }}</span>
                </b-badge>
              </div>
            </template>
          </b-table>

          <div class="mx-2 mb-2 pb-1">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"></span>
              </b-col>

              <!-- Pagination -->

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="state.currentPage"
                  :total-rows="articles.length"
                  :per-page="state.perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>

                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BModal,
  BFormInput,
  VBModal,
  BForm,
  BLink,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { reactive, ref, onMounted, toRef, computed } from "@vue/composition-api";
import moment from "moment";
import qDeviseUtils from "@/utils/qDeviseUtils";
import { qDecrypted } from "@/services/qeCrypt";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    BFormGroup,
    VBModal,
    BForm,
    BImg,
    BLink,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  props: {},
  data() {
    return {
      inventaires: [],
      articles: [],
    };
  },

  mounted() {
    this.inventaires = JSON.parse(localStorage.getItem("inventaire"));
    // console.log(this.inventaires.articles[0].libelle)
    this.articles = this.inventaires.articles;
    console.log(this.articles);
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
  },
  setup() {
    const state = reactive({
      perPage: 30,
      currentPage: 1,
      filter: "",
      success: false,
      empty: false,
      warring: false,
      loading: false,
    });
    const tableColumns = [
      { key: "libelle", label: "libelle", sortable: true },
      { key: "qte", label: "quantité de depart", sortable: true },
      { key: "qte_restante", label: "quantité restante système", sortable: true },
      { key: "article_restant", label: "quantité restante magasin", sortable: true },
    ];
    const inventaires = ref([]);
    const articles = ref([]);
    const perPageOptions = [30, 50, 100, 150, 250, 500];

    const formatter = (amount) => {
      const dataToDecrypt = localStorage.getItem("entreprise_info");
      return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
    };

    return {
      state,
      perPageOptions,
      inventaires,
      articles: [],
      tableColumns,
      formatter,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
